<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <span> <button
        class="compact ui button btn-primary text-height"
        @click="onAddCommom"
      >
        Đồng bộ hồ sơ
      </button>
      </span>
      <span class="d-flex">
        <b-form-input
          id="maHoSo"
          v-model="filter.maHoSo"
          v-trim-input
          class="width-100"
          type="text"
          placeholder="Nhập mã hồ sơ"
        />
        <button
          class="compact ui button btn-primary text-height"
          @click="filterdanhSachDongBo"
        >
          Tìm kiếm
        </button>
      </span>
    </div>
    <good-table
      ref="danhSachDongBo"
      class="mt-1"
      :columns="columns"
      :rows="danhSachDongBo"
      :total="tongSoDongBo"
      :selectable="false"
      @page-change="pageChange"
    >
      <template
        slot="custom-row"
        slot-scope="props"
      >

        <span v-if="props.props.column.field == 'ngayTiepNhan'">
          <div>{{ formatDate(props.props.row) }}</div>
        </span>
      </template>
    </good-table>
  </b-card>
</template>
<script>
import {
  BCard, BFormInput,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'

export default {
  components: {
    GoodTable,
    BCard,
    BFormInput,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã hồ sơ',
          field: 'maHoSo',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenTTHC',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Trạng thái',
          field: 'trangThaiHoSo',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Chủ hồ sơ',
          field: 'maDoiTuong',
          thClass: 'text-center',
          width: '250px',
        },
        {
          label: 'Ngày nộp',
          field: 'ngayTiepNhan',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày hẹn trả',
          field: 'ngayHenTra',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        // {
        //   label: 'Thao tác',
        //   thClass: 'text-center',
        //   tdClass: 'vertical-align-middle',
        //   field: 'actions',
        //   width: '100px',
        // },
      ],
      filter: {
        maHoSo: '',
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maTrangThai: '',
        tenTrangThai: '',
        loaiTrangThaiId: null,
        status: true,
        dataNavId: [],
      },
      selectedRows: [],
      selectedNumber: 0,
      danhSachDongBo: [],
      treeBoxWidth: '250px',
      isShowFooter: false,
      isShow: false,
      tongSoDongBo: 0,
      errorMessage: '',
      danhSachLoaiTrangThai: [],
      danhSachNav: [],
    }
  },
  created() {
    // this.filterdanhSachDongBo()
  },
  methods: {
    onAddCommom() {
      this.$axios.post(END_POINTS.DONG_BO.DONG_BO_DVCQG).then(response => {
        // console.log(response.data.data)
        if (response && response.data && response.data.data) {
          // this.tongSoDongBo = 1
          // this.danhSachDongBo = [response.data.data]
        }
      })
    },
    onSearch() {},
    formatDate(value) {
      if (!_isEmpty(value)) { return moment(value).format('DD/MM/YYYY | HH:mm:ss') }
      return ''
    },
    pageChange() {
      const data = this.$refs.danhSachDongBo.getCurrentPage()
      const payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.get(END_POINTS.DONG_BO.DANH_SACH_DVCQG,
        payload,
        false).then(response => {
        if (response && response.data && response.data.data) {
          this.tongSoDongBo = 1
          this.danhSachDongBo = this.danhSachDongBo.push(response.data.data)
        }
      })
    },
    filterdanhSachDongBo() {
      this.filter = {
        ...this.filter,
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.danhSachDongBo) {
        payload = {
          ...payload,
          ...this.$refs.danhSachDongBo.getCurrentPage(),
        }
      }
      // if (param.maTrangThai) {
      //   payload = {
      //     ...payload,
      //     pageNumber: 1,
      //     pageSize: this.filter.pageSize,
      //     maTrangThai: param.maTrangThai ? param.maTrangThai.replace(/\s+/g, ' ').trim() : '',
      //   }
      // }
      // if (param.tenTrangThai) {
      //   payload = {
      //     ...payload,
      //     pageNumber: 1,
      //     pageSize: this.filter.pageSize,
      //     tenTrangThai: param.tenTrangThai ? param.tenTrangThai.replace(/\s+/g, ' ').trim() : '',
      //   }
      // }
      // if (this.filter.status || !this.filter.status) {
      //   payload = {
      //     ...payload,
      //     pageNumber: 1,
      //     pageSize: this.filter.pageSize,
      //   }
      // }
      // if (this.filter.status === null) {
      //   delete payload.status
      // }
      this.$axios.post(END_POINTS.DONG_BO.DANH_SACH_DVCQG, {
        ...payload,
      }).then(response => {
        // console.log(response.data.data)
        if (response && response.data && response.data.data) {
          this.tongSoDongBo = 1
          this.danhSachDongBo = [response.data.data]
        }
      })
    },
  },
}
</script>
<style lang="scss" scpoed>
.width-100 {
    width: 500px !important;
    margin-right: 5px;
}
</style>
